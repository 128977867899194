<template>
<div class="main gray-bg">
  <van-nav-bar class="top-bar" title="消息中心" left-arrow @click-left="onClickLeft"/>
  <van-list class="notice-list" v-model:loading="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="getNoticeList">
    <van-cell v-for="(item, key) in list" :key="key" class="notice-cell" clickable :to="'/tool/notice-detail/'+item.id">
      <template #title>
        <div class="notice-title"><span v-if="!item.is_read" class="dot"></span>{{ item.title }}</div>
        <div class="notice-time">{{date(item.create_time, 'Y-m-d H:i:s')}}</div>
      </template>
    </van-cell>
  </van-list>
</div>
</template>

<script>
import {Button, Cell, List, NavBar,} from "vant";
import {reactive, ref} from "vue";
import {noticeList} from "@/api/tool";
import {date,onClickLeft} from "@/utils/function";
import "@/style/common.css"

export default {
  name: "ToolNoticeList",
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [List.name]: List,
    [Button.name]: Button,
  },
  setup() {
    const list = ref([])
    const query = reactive({
      last: 0,
      limit: 10
    })
    const loading = ref(false);
    const finished = ref(false);
    const getNoticeList = () => {
      loading.value = true;
      let oldList = list.value;
      noticeList(query).then(({data}) => {
        loading.value = false;
        list.value = [...oldList,...data.list]
        query.last = data.last
        if (data.list.length < query.limit || data.last == 0) {
          finished.value = true;
        }
      })
    }
    return {
      list,
      loading,
      finished,
      onClickLeft,
      getNoticeList,
      date,
    }
  }
}
</script>

<style scoped>
.notice-list{
  padding:0px 16px;
}
.notice-cell{
  margin:12px auto;
  padding:10px 16px;
  border-radius: 4px;
  height: 68px;
  box-sizing: border-box;
  overflow: hidden;
  align-items: center;
}
.notice-cell :deep(.van-cell__title){
  text-align: left;
}
.notice-title{
  font-size: 15px;
  font-weight: 500;
  color: #1D2129;
  line-height: 22px;
}
.notice-title .dot{
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #F53F3F;
  border-radius:180%;
  margin-right:5px;
}
.notice-time{
  margin-top:8px;
  font-size: 12px;
  font-weight: 400;
  color: #86909C;
  line-height: 12px;
}
</style>
